.player {
  background-color: #fff;
  box-shadow: 0 -.2rem 2rem rgba(0,0,0,.4);
  display: flex;
  flex-flow: row wrap;
  height: 10rem;
  position: relative;
  z-index: 100;

  @media screen and (min-width: 700px) {
    flex-flow: row nowrap;
    height: 6rem;
  }

  @media (prefers-color-scheme: dark) {
    background-color: var(--dark-color);
  }
}

.player-artwork {
  align-items: center;
  display: flex;
  flex: 0 0 6rem;
  flex-direction: column;
  height: 6rem;
  justify-content: center;
  width: 6rem;

  // @media (prefers-color-scheme: dark) {
  //   filter: brightness(90%);
  // }
}

.player-track-details {
  border-right: 1px solid var(--dark-color);
  display: flex;
  flex: 1 1 13rem;
  font-weight: 500;
  flex-direction: column;
  height: 6rem;
  justify-content: center;
  padding: 0 1rem;
  position: relative;
  width: 13rem;

  @media screen and (min-width: 700px) {
    flex: 0 0 18rem;
    padding: 0 1.5rem;
    width: 18rem;
  }

  @media (prefers-color-scheme: dark) {
    border-color: rgba(#fff, .1);
  }
}

.player-track-title { 
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 0 .4rem;

  @media screen and (min-width: 700px) {
    font-size: 1.6rem;
  }
}

.player-track-timer {
  animation: fadein .6s linear;
}

.player-track-info-button {
  align-items: center;
  background-color: var(--light-color);
  border-radius: 50%;
  border: 1px solid var(--dark-color);
  cursor: pointer;
  display: flex;
  font-family: var(--body-font);
  font-size: 1.2rem;
  font-weight: 700;
  height: 1.8rem;
  justify-content: center;
  margin: 0;
  padding: .3rem 0 0;
  position: absolute;
  right: 1rem;
  transition: all .2s ease-in-out;
  top: .8rem;
  width: 1.8rem;

  @media (prefers-color-scheme: dark) {
    background-color: var(--dark-color);
    border-color: var(--light-color);
    color: var(--light-color);
  }
}

.player-track-info-button:focus,
.player-track-info-button:hover {
  background-color: var(--dark-color);
  color: var(--light-color);

  @media (prefers-color-scheme: dark) {
    background-color: var(--light-color);
    color: var(--dark-color);
  }
}

.player-artwork svg {
  height: 5rem;
  width: 5rem;
}

.player-controls {
  display: flex;
  flex-flow: row nowrap;
  height: 6rem;

  @media screen and (min-width: 500px) {
    border-right: 1px solid var(--dark-color);
  }

  @media (prefers-color-scheme: dark) {
    border-color: rgba(#fff, .1);
  }
}

.player-progress {
  background-size: cover;
  align-items: center;
  border-top: 1px solid var(--dark-color);
  display: flex;
  height: 4rem;
  order: 6;
  position: relative;
  width: 100%;

  @media screen and (min-width: 700px) {
    border-top: 0;
    border-right: 1px solid var(--dark-color);
    flex-basis: auto;
    flex-grow: 1;
    height: 6rem;
    order: initial;
    width: auto;
  }

  @media (prefers-color-scheme: dark) {
    border-color: rgba(#fff, .1);
  }
}

.player-progress-wrapper {
  background-color: var(--light-color);
  cursor: pointer;
  height: 100%;
  position: relative;
  width: 100%;

  @media (prefers-color-scheme: dark) {
    background-color: var(--dark-color);
  }
}

.player-progress-loader {
  background-color: var(--light-color);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  @media (prefers-color-scheme: dark) {
    background-color: var(--dark-color);
  }
}

.player-progress-bar {
  background-color: var(--dark-color);
  background-clip: border-box;
  height: 100%;
  transition: all .1s ease-in-out;
}

.player-waveform {
  background-size: 100% 100%;
  bottom: 0;
  left: 0;
  filter: invert(10%);
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.player-button {
  background: transparent;
  cursor: pointer;
  border: 0;
  padding: 0 1rem;
  width: 5rem;
}

.player-button > svg {
  fill: var(--dark-color);
  transition: all .2s ease-in-out;

  @media (prefers-color-scheme: dark) {
    fill: var(--light-color);
  }
}

.player-button:focus {
  outline: none;
}

.player-button:disabled {
  opacity: .2;
}

.play-button {
  width: 4rem;
}

.play-button > svg {
  height: 3rem;
}

.player-volume {
  display: none;
  flex: 0;
  flex-flow: row nowrap;
  justify-content: center;
  position: relative;
  width: 5rem;

  @media screen and (min-width: 500px) {
    display: flex;
  }
}

.player-volume > button {
  background-color: var(--light-color);
  z-index: 102;

  @media (prefers-color-scheme: dark) {
    background-color: var(--dark-color);
  }
}

.player-volume > div {
  background-color: var(--light-color);
  bottom: 100%;
  border-radius: 1rem 0 0 0;
  box-shadow: -.2rem -.2rem 2rem rgba(0,0,0,.3);
  display: flex;
  max-height: 0;
  overflow: hidden;
  justify-content: center;
  position: absolute;
  transition: all .2s ease-in-out;
  width: 100%;
  z-index: 101;

  @media (prefers-color-scheme: dark) {
    background-color: var(--dark-color);
  }
}

@media screen and (min-width: 800px) {
  .player-volume:hover > div,
  .player-volume:active > div,
  .player-volume:focus > div {
    max-height: 12rem;
  }
}

input[type=range] {
  background: var(--light-color);
  height: 17px;
  appearance: none;
  margin: 50px 0;
  width: 100px;
  transform: rotate(270deg);

  @media (prefers-color-scheme: dark) {
    background: var(--dark-color);
  }
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px var(--dark-color);
  background: var(--dark-color);
  border-radius: 3rem;
  border: 0px solid var(--dark-color);

  @media (prefers-color-scheme: dark) {
    background-color: var(--light-color);
  }
}

input[type=range]::-webkit-slider-thumb {
  background: var(--dark-color);
  box-shadow: 0px 0px 0px var(--dark-color);
  border: 2px solid var(--light-color);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  margin-top: -5px;

  @media (prefers-color-scheme: dark) {
    background: var(--light-color);
    border-color: var(--dark-color);
  }
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--dark-color);

  @media (prefers-color-scheme: dark) {
    background: var(--light-color);
  }
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px var(--dark-color);
  background: var(--dark-color);
  border-radius: 3rem;
  border: 0px solid var(--dark-color);

  @media (prefers-color-scheme: dark) {
    background: var(--light-color);
    border-color: var(--light-color);
  }
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px var(--dark-color);
  border: 1px solid var(--light-color);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--dark-color);
  cursor: pointer;

  @media (prefers-color-scheme: dark) {
    background: var(--light-color);
    border-color: var(--dark-color);
  }
}

input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: var(--dark-color);
  border: 0px solid var(--dark-color);
  border-radius: 2px;
  box-shadow: 0px 0px 0px var(--dark-color);
}

input[type=range]::-ms-fill-upper {
  background: var(--dark-color);
  border: 0px solid var(--dark-color);
  border-radius: 2px;
  box-shadow: 0px 0px 0px var(--dark-color);
}

input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px var(--dark-color);
  border: 1px solid #2497E3;
  height: 10px;
  width: 18px;
  border-radius: 25px;
  background: var(--dark-color);
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: var(--dark-color);
}

input[type=range]:focus::-ms-fill-upper {
  background: var(--dark-color);
}

.soundcloud-iframe {
  position:relative;
  visibility:hidden;
  z-index:-1;
}

.loading-text {
  display: flex;

  span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
  }

  span:nth-child(1) {
    animation-delay: 0.0s;
  }

  span:nth-child(2) {
    animation-delay: 0.1s;
  }

  span:nth-child(3) {
    animation-delay: 0.2s;
  }
}

@-webkit-keyframes ellipsis-dot {
  0%    { opacity: 0; }
  50%   { opacity: 1; }
  100%  { opacity: 0; }
}

@keyframes ellipsis-dot {
  0%    { opacity: 0; }
  50%   { opacity: 1; }
  100%  { opacity: 0; }
}
  
